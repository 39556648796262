import.meta.glob([
    '../img/**',
]);

import axios from 'axios';

window.axios = axios;

import Pikaday from 'pikaday';

window.Pikaday = Pikaday;

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://0d4d05187266400baf0a9fa340698dd9@o4505602835873792.ingest.sentry.io/4505602861760512",
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    enabled: import.meta.env.PROD,
    integrations: [
        Sentry.metrics.metricsAggregatorIntegration(),
    ],
});
window.Sentry = Sentry;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true; // without this one it wasn't working in Firefox
window.axios.defaults.withXSRFToken = true;

window.axios.interceptors.response.use(
    (response) => {
        Sentry.metrics.gauge("request_roundtrip_time", new Date().getTime() - response.config.meta.requestStartedAt, {
            tags: {url: response.config.url},
            unit: "millisecond",
        });
        return response
    },
    (error) => {
        Sentry.metrics.gauge("request_roundtrip_time", new Date().getTime() - error.config.meta.requestStartedAt, {
            tags: {url: error.config.url},
            unit: "millisecond",
        });
        console.log(error.response)
        console.log(`ERROR Execution time for: ${error.config.url} - ${new Date().getTime() - error.config.meta.requestStartedAt} ms`)

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status !== 422 && error.response.status !== 400){
                Sentry.captureException(error.response.data);
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // Sentry.captureException(error.request);

        } else {
            // Something happened in setting up the request that triggered an Error
            Sentry.captureException(error.message);
        }

        return Promise.reject(error);
    },
);

window.axios.interceptors.request.use(x => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    x.meta = x.meta || {}
    x.meta.requestStartedAt = new Date().getTime();
    return x;
});

